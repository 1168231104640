import React from 'react'

import {
  Flex,
  Button,
  Image,
  Stack,
  Box,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import LogoKindnessLove from 'assets/logo.png'

interface IProps {
  scrollToFooter(): void
  scrollToServices(): void
  scrollToBenefits(): void
}

export const HeaderComponent: React.FC<IProps> = ({
  scrollToFooter,
  scrollToServices,
  scrollToBenefits,
}) => {
  const [isLargerThan910] = useMediaQuery('(min-width: 910px)')

  return (
    <Flex as="header" w="100%" boxShadow="xl">
      <Flex
        as="div"
        w="100%"
        maxWidth={1440}
        h="20"
        mx="auto"
        mt="2"
        px="6"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Box as="div" display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Image
              src={LogoKindnessLove}
              alt="Logo kindness love transportation"
              objectFit="cover"
            />
            <Box
              ml="2"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <Text
                color="red.550"
                fontWeight="bold"
                lineHeight="normal"
                fontSize="sm">
                Kindness and Love transportation
              </Text>
              <Text color="red.550" lineHeight="normal" fontSize="10px">
                The best care for You and your Loved Ones
              </Text>
            </Box>
          </Box>

          {isLargerThan910 && (
            <Stack ml="8" spacing="2" direction="row" align="center">
              <Button
                bg="white"
                color="gray.900"
                fontWeight="bold"
                size="lg"
                _hover={{ bg: 'purple.100' }}
                onClick={scrollToServices}>
                Services
              </Button>
              <Button
                bg="white"
                color="gray.900"
                fontWeight="bold"
                size="lg"
                _hover={{ bg: 'purple.100' }}
                onClick={scrollToBenefits}>
                Benefits
              </Button>
            </Stack>
          )}
        </Box>

        {isLargerThan910 && (
          <Button
            bg="purple.50"
            fontWeight="bold"
            size="lg"
            _hover={{ bg: 'purple.100' }}
            onClick={scrollToFooter}>
            Contact us
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
