/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from 'react'

import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  SimpleGrid,
  Text,
  Divider,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import ImageAmbulatoryTransport from 'assets/ambulatoryTransport.png'
import ImageBenefits3 from 'assets/benefits3.png'
import ImageCart from 'assets/cart.png'
import ImageCart2 from 'assets/cart2.png'
import ImageLogoFooterNew from 'assets/logoFooter_new.png'
import ImageLogoFooter from 'assets/logoFooter.png'
import ImageLongDistanceTransport from 'assets/longDistanceTransport.png'
import ImageNonEmergencyServices from 'assets/nonEmergencyServices.png'
import ImagePatient from 'assets/patient.png'
import ImageRoads from 'assets/roads.png'
import ImageWheelchairVehicleServices from 'assets/wheelchairVehicleServices.png'
import { HeaderComponent } from 'components/Header/HeaderComponent'

export const HomeScreen: React.FC = () => {
  const footerRef = useRef<null | HTMLDivElement>(null)
  const servicesRef = useRef<null | HTMLDivElement>(null)
  const benefitsRef = useRef<null | HTMLDivElement>(null)

  const [isLargerThan910] = useMediaQuery('(min-width: 910px)')
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToBenefits = () => {
    benefitsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Flex direction="column" h="100vh">
      <HeaderComponent
        scrollToFooter={scrollToFooter}
        scrollToServices={scrollToServices}
        scrollToBenefits={scrollToBenefits}
      />

      <Flex width="100%" maxWidth={1440} mx="auto" direction="row">
        <SimpleGrid
          columns={{ base: 0, sm: 0, md: 2 }}
          row={2}
          templateAreas={{
            // eslint-disable-next-line quotes
            base: `'image2' 'image1'`,
            // eslint-disable-next-line quotes
            sm: `'image2' 'image1'`,
            // eslint-disable-next-line quotes
            md: `'image1 image2'`,
            // eslint-disable-next-line quotes
            lg: `'image1 image2'`,
          }}>
          <Box
            pr="10"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            px="4"
            py={8}
            gridArea="image1">
            <Box
              display="flex"
              alignItems={['flex-start', 'flex-start', 'flex-start']}
              justifyContent={['flex-start', 'flex-start', 'flex-start']}
              flexDirection="column">
              <Text fontWeight="bold" fontSize={['2xl', '2xl', '4xl']}>
                Non-Emergency Medical Transportation
              </Text>

              <Text mt="2" fontSize={['14px', '18px']}>
                Our most important achievement is earning the trust of our
                customers and partners. We operate with integrity, push to
                overcome obstacles, making with great results
              </Text>

              <Text
                fontSize={['1xl', '2xl', 'lg']}
                fontWeight="medium"
                mt="3"
                mb="1">
                Languages:
              </Text>
              <Text fontSize={['14px', '18px']} pb="2">
                English, Spanish and Portuguese
              </Text>
            </Box>

            <HStack
              spacing="3"
              fontSize={['14px', '14px', '20px']}
              mt="5"
              pb="4">
              <Button
                bg="purple.50"
                fontWeight="bold"
                size={['sm', 'md', 'lg']}
                _hover={{ bg: 'purple.100' }}
                onClick={scrollToFooter}>
                Contact us
              </Button>
            </HStack>

            {/* <Box display="flex" flexDirection="row">
              <HStack
                spacing="3"
                fontSize={['14px', '14px', '20px']}
                mt="5"
                pb="4">
                <Button
                  bg="purple.50"
                  fontWeight="bold"
                  size={['sm', 'md', 'lg']}
                  _hover={{ bg: 'purple.100' }}
                  onClick={scrollToFooter}>
                  Contact us
                </Button>
              </HStack>
              <Box ml="8">
                <Text fontSize={['md', 'lg', '2xl']} fontWeight="bold">
                  Languages:
                </Text>
                <Text fontSize={['sm', 'md', 'lg']} fontWeight="bold">
                  English
                </Text>
                <Text fontSize={['sm', 'md', 'lg']} fontWeight="bold">
                  Spanish
                </Text>
                <Text fontSize={['sm', 'md', 'lg']} fontWeight="bold">
                  Portuguese
                </Text>
              </Box>
            </Box> */}
          </Box>

          <Image
            src={ImageRoads}
            alt="Image roads"
            w="100%"
            gridArea="image2"
            h={!isLargerThan768 ? '250px' : '100%'}
            objectFit="cover"
          />
        </SimpleGrid>
      </Flex>

      <Flex bg="red.550" color="white">
        <Box
          width="100%"
          maxWidth={1440}
          mx="auto"
          px="4"
          py="8"
          mt={['0', '0', '10']}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            px="10">
            <Text
              fontWeight="bold"
              fontSize={['2xl', '4xl']}
              letterSpacing="tight"
              lineHeight="10"
              textAlign="center">
              Kindness and Love Transportation
            </Text>
            <Text
              fontSize={['md', 'lg']}
              letterSpacing="tight"
              lineHeight="8"
              mt={['3', '3', '10']}
              textAlign="center"
              autoCapitalize="false">
              Our drivers are well trained to take care of you and your loved
              ones
            </Text>
          </Box>
          <Grid
            my={['6', '10']}
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={2}>
            <GridItem colSpan={4} rowSpan={1} mt="4">
              <Image
                src={ImagePatient}
                alt="Image patient"
                boxSize="100%"
                objectFit="cover"
              />
            </GridItem>
          </Grid>
        </Box>
      </Flex>

      <Flex
        ref={servicesRef}
        width="100%"
        maxWidth={1440}
        mx="auto"
        py={['5', '10']}
        direction="column"
        px="4">
        <Text fontWeight="bold" fontSize={['2xl', '3xl']} mb={['3', '10']}>
          Our transport services include:
        </Text>

        <SimpleGrid
          columns={2}
          spacing={[2, 10]}
          minChildWidth={['300px', '460px']}>
          <Box p="8" boxShadow="2xl">
            <Image
              src={ImageNonEmergencyServices}
              width="100%"
              objectFit="contain"
            />

            <Text fontWeight="bold" fontSize={['xl', '2xl']} mb="2">
              Non emergency transport
            </Text>

            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Physical Therapy
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Pulmonary and cardiac Rehabilitaion
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Rehabilitation Center
              </Text>
            </Box>
          </Box>

          <Box p="8" boxShadow="2xl">
            <Image
              src={ImageWheelchairVehicleServices}
              width="100%"
              objectFit="contain"
            />

            <Text fontWeight="bold" fontSize={['xl', '2xl']} mb="2">
              Wheelchair Services available
            </Text>

            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Doctor Appointments
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Dialysis treatment
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Out patient surgery
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Hospital/Nursing homes
              </Text>
            </Box>
          </Box>

          <Box p="8" boxShadow="2xl">
            <Image
              src={ImageAmbulatoryTransport}
              width="100%"
              objectFit="contain"
            />

            <Text fontWeight="bold" fontSize={['xl', '2xl']} mb="2">
              Ambulatory
            </Text>

            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Dental Appointments
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Trips to Schools & Churches
              </Text>
            </Box>
          </Box>

          <Box p="8" boxShadow="2xl">
            <Image
              src={ImageLongDistanceTransport}
              width="100%"
              objectFit="contain"
            />

            <Text fontWeight="bold" fontSize={['xl', '2xl']} mb="2">
              Long Distance Transport
            </Text>

            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Long distance trips
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" pb="1">
              <CheckCircleIcon color="cyan.600" />
              <Text ml="2" color="blackAlpha.600">
                Airports trips
              </Text>
            </Box>
          </Box>
        </SimpleGrid>
      </Flex>

      <Flex ref={benefitsRef} bg="red.550" color="white">
        <Box mx="auto" py="2" width="100%" maxWidth={1440} px="5">
          <Text
            fontWeight="bold"
            fontSize={['3xl', '5xl']}
            mb="10"
            textAlign="center">
            Benefits of working with us:
          </Text>

          <SimpleGrid column={2} spacingX={10} minChildWidth="350px">
            {isLargerThan910 && (
              <Box h={600} px="2" pt="8">
                <Image
                  src={ImageCart}
                  transition="0.5s ease"
                  transform="translate(100%, 0%)"
                />

                <Image
                  src={ImageCart2}
                  transition="0.5s ease"
                  transform="translate(25%, -50%)"
                />

                <Image
                  src={ImageBenefits3}
                  transition="0.5s ease"
                  transform="translate(180%, -125%)"
                />
              </Box>
            )}

            <Box
              pr="9"
              px="2"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              pb="8">
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Kindness and Love
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Wheelchair clients from their place to any destination
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • We will treat you and your loved ones like family
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Elderly and disability patient transportation
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • 24 hours service ( Please, by appointment )
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Routine medical appointments
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Local errands appointments
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Shopping excursions
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Safety and Comfort
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Outpatient surgery
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Routine checkups
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Hospital to home
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Hospital visits
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Nursing home
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Airport trips
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Home care
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Banking
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Dialysis
              </Text>
              <Text fontWeight="bold" fontSize={['1xl', '2xl']} mb="2">
                • Punctuality
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>

      <Flex mx="auto" py="10" px="10">
        <Box width="100%" maxWidth={1440}>
          <Text
            textAlign="center"
            fontWeight="bold"
            fontSize={['2xl', '3xl']}
            color="red.550">
            The best care for You and your Loved Ones
          </Text>
        </Box>
      </Flex>

      <Flex ref={footerRef} as="footer" bg="red.550">
        <Box
          mx="auto"
          py="10"
          width="100%"
          maxWidth={1440}
          px="10"
          pb="10"
          pt={isLargerThan768 ? '110px' : '36px'}>
          <SimpleGrid
            flex="1"
            column={4}
            spacing={2}
            minChildWidth="300px"
            justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg="white"
              borderRadius={16}
              px="8"
              py="3">
              <Image
                src={ImageLogoFooterNew}
                alt="Logo kindness love transportation"
                objectFit="cover"
              />

              <Text
                color="red.550"
                fontWeight="bold"
                lineHeight="normal"
                fontSize="sm"
                mt="3">
                Kindness and Love transportation
              </Text>
              <Text color="red.550" lineHeight="normal" fontSize="10px">
                The best care for You and your Loved Ones
              </Text>
            </Box>

            <Box color="white" mt={isLargerThan768 ? 0 : 4}>
              <Text fontWeight="bold" fontSize="2xl" mb="3">
                Company
              </Text>

              <Text fontSize={['sm', 'md']} mb="2">
                Non emergency transportation providing safety, comfort, kindness
                and love
              </Text>
            </Box>

            <Box color="white">
              <Text fontWeight="bold" fontSize="2xl" mb="3">
                Contact
              </Text>

              <Text fontSize="lg" fontWeight="medium" mb="3">
                Calls, Whatsapp, Text Messages:
                <Text fontSize={['sm', 'md']} fontWeight="normal" mb="2">
                  (443) 942-3284 and (571) 580-3521
                </Text>
              </Text>

              <Text fontSize="lg" fontWeight="medium" mb="3">
                Email:
                <Text fontSize={['sm', 'md']} fontWeight="normal" mb="2">
                  kindnessandlovetransportation@gmail.com
                </Text>
              </Text>

              <Text fontSize="lg" fontWeight="medium" mb="2">
                Open:
                <Text fontSize={['sm', 'md']} fontWeight="normal" mb="2">
                  24 hours/7 Days a week
                </Text>
              </Text>
            </Box>

            <Box color="white">
              <Text fontWeight="bold" fontSize="2xl" mb="3">
                Location
              </Text>

              <Text fontSize="lg" mb="2">
                7583 Aspenpark Rd, Lorton, VA 22079
              </Text>
            </Box>
          </SimpleGrid>

          <Box color="white" mt="100px">
            <Divider color="white" />

            <Text color="white" textAlign="center" mt="5">
              Copyright @ 2022 K&L Transportation. All Right Reserved
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}
