import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    purple: {
      '500': '#8C2BCC',
    },
    red: {
      '550': '#FF0000',
    },
  },
  fonts: {
    heading: 'Manrope',
    body: 'Manrope',
  },
  styles: {
    global: {
      body: {
        bg: 'white',
      },
    },
  },
})
