import React from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import { HomeScreen } from 'screens/Home'
import { theme } from 'theme/theme'

export function App() {
  return (
    <ChakraProvider theme={theme}>
      <HomeScreen />
    </ChakraProvider>
  )
}
